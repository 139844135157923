import http from "@/helpers/http";
import qs from "qs";
import { pack } from "@/helpers/utils";

const service = "faUnidrinkAds/";

export function getAdvertList(form) {
  return http.request({
    url: "/faUnidrinkAds/listByPage",
    method: "POST",
    params: {
      page: form.currPage,
      pageSize: form.pageSize,
    },
    data: pack(form),
  });
}

export function addAdvert(form) {
  return http.request({
    url: "/faUnidrinkAds/save",
    method: "POST",
    data: pack(form),
  });
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}


export function editAdvert(form) {
  return http.request({
    url: `/faUnidrinkAds/updateById`,
    method: "POST",
    data: pack(form),
  });
}

export function toggleAdvert(id, status) {
  return http.request({
    url: `/faUnidrinkAds/updateById`,
    method: "POST",
    data: {
      body: {
        id,
        switchFlag: status,
      },
    },
  });
}

export function drag(ids) {
	return http.request({
		url: service + "drag",
		method: "POST",
		params: {
			ids: ids
		}
	})
}

export function deleteAdvert(id) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/ads/del",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      action: "del",
      ids: id,
    }),
  });
}

export function dragAdvert(ids, changeid) {
  return http.request({
    url: "/SYTdknLasJ.php/ajax/weigh",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      ids,
      changeid,
      field: "weigh",
      orderway: "desc",
      table: "unidrink_ads",
      pk: "id",
    }),
  });
}
